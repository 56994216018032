import React from 'react'
import loadable from "@loadable/component";

const CustomLink = loadable(() => import('../../components/common/CustomLink'))

const LinkListGroup = React.memo(({ data, skip = 0, limit = 0, className = "" }) => {

    return (
        <div className={`main__categories container-xl ${className}`}>
            <ul className="main__categories__list row px-2">
                {
                    data.map((v, i) => {
                        if (i >= skip && i < (skip + (limit == 0 ? data.length : limit))) {
                            return (
                                <li key={i} className={`main__categories__list__item col-lg col-md-6 py-2 pr-2 pl-2 ${i === data.length - 1 ? "d-xl-block d-none" : ''}`}>
                                    <CustomLink link={v.url} text={v.link_name} />
                                </li>
                            )
                        }
                        return null
                    })
                }
            </ul>
        </div>
    )
})

export default LinkListGroup